import { StackLayout } from '@leagueplatform/genesis-core';
import { MainPageContainer } from '@leagueplatform/web-common-components';

import cignaLogo from 'assets/cigna-logo.png';
import { AboutBodySection } from 'components/about/about-body-section.component';
import { useHistory } from '@leagueplatform/routing';
import { useIntl } from '@leagueplatform/locales';
import { HeroSection } from 'components/hero-section/hero-section.component';
import { ABOUT_US_DESCRIPTION_TEXT } from 'common/constants';

const AboutCignaHealthcare = () => {
  const history = useHistory();
  const { formatMessage } = useIntl();

  return (
    <MainPageContainer width="100%">
      <HeroSection
        title={formatMessage({ id: 'STR_ABOUT_CIGNA_HEALTHCARE' })}
        goBack={history.goBack}
        height="190px"
      />
      <StackLayout
        css={{
          backgroundColor: '$surfaceBackgroundPrimary',
          gap: '$half',
          padding: '$oneAndHalf',
          border: 'thin solid $onSurfaceBorderSubdued',
          borderRadius: '$medium',
          maxWidth: 1048,
          marginInlineStart: '$none',
          '@desktop': { marginInlineStart: '$three' },
        }}
      >
        <AboutBodySection
          htmlString={ABOUT_US_DESCRIPTION_TEXT}
          image={cignaLogo}
          imageAltId="STR_ABOUT_CIGNA_HEALTHCARE"
        />
      </StackLayout>
    </MainPageContainer>
  );
};

export default AboutCignaHealthcare;
