import { GDSIconProps, GDSStyleObject } from '@leagueplatform/genesis-core';
import { EXTERNAL_LINK } from 'common/constants';
import { LocalesKey } from 'common/types';

export interface FooterLinkProps {
  text: LocalesKey;
  url?: string;
  linkProps?: GDSStyleObject;
  icon?: GDSIconProps['icon'];
  target?: string;
}

const boldTextLink: GDSStyleObject = {
  fontWeight: 'bold',
};

export const footerLinksConfig = (): FooterLinkProps[] => [
  {
    text: 'STR_LANGUAGE_ASSISTANCE',
    url: EXTERNAL_LINK.LANGUAGE_ASSISTANCE,
    target: '_blank',
    linkProps: boldTextLink,
    icon: 'interfaceExternalLink',
  },
  {
    text: 'STR_TERMS_OF_USE',
    url: EXTERNAL_LINK.TERMS_OF_USE,
    target: '_blank',
    linkProps: boldTextLink,
    icon: 'interfaceExternalLink',
  },
];
export const footerSubLinksConfig = (): FooterLinkProps[] => [
  {
    text: 'STR_PRIVACY_POLICY',
    url: EXTERNAL_LINK.PRIVACY_POLICY,
    target: '_blank',
    icon: 'interfaceExternalLink',
  },
  {
    text: 'STR_PRIVACY_NOTICES',
    url: EXTERNAL_LINK.PRIVACY_NOTICES,
    target: '_blank',
    icon: 'interfaceExternalLink',
  },
  {
    text: 'STR_PRIVACY_FORMS',
    url: EXTERNAL_LINK.PRIVACY_FORMS,
    target: '_blank',
    icon: 'interfaceExternalLink',
  },
  {
    text: 'STR_HIPPA_NOTICE_OF_PRIVACY_PRACTICES',
    url: EXTERNAL_LINK.HIPPA_NOTICE_OF_PRIVACY_PRACTICES,
    target: '_blank',
    icon: 'interfaceExternalLink',
  },
  {
    text: 'STR_GRAMM_LEACH_BILLEY_ACT_NOTICE',
    url: EXTERNAL_LINK.GRAMM_LEACH_BILLEY_ACT_NOTICE,
    target: '_blank',
    icon: 'interfaceExternalLink',
  },
  {
    text: 'STR_NONDISCRIMINATION_NOTICE',
    url: EXTERNAL_LINK.NONDISCRIMINATION_NOTICE,
    target: '_blank',
    icon: 'interfaceExternalLink',
  },
  {
    text: 'STR_APPEALS',
    url: EXTERNAL_LINK.APPEALS,
    target: '_blank',
    icon: 'interfaceExternalLink',
  },
  {
    text: 'STR_GRIEVANCES',
    url: EXTERNAL_LINK.GRIEVANCES,
    target: '_blank',
    icon: 'interfaceExternalLink',
  },
  {
    text: 'STR_DISCLAIMERS',
    url: EXTERNAL_LINK.DISCLAIMERS,
    target: '_blank',
    icon: 'interfaceExternalLink',
  },
];
