/* eslint-disable react/prop-types */
import { VisuallyHidden } from '@leagueplatform/genesis-commons';
import {
  Icon,
  GDSIconProps,
  Box,
  TextAction,
  GDSStyleObject,
} from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import { LocalesKey } from 'common/types';

export interface LinkComponentProps {
  text: LocalesKey;
  url?: string;
  linkProps?: GDSStyleObject;
  icon?: GDSIconProps['icon'];
  target?: string;
}

export const LinkComponent = ({
  text,
  url,
  linkProps,
  icon,
  target,
}: LinkComponentProps) => {
  const { formatMessage } = useIntl();

  return (
    <TextAction
      href={url}
      target={target}
      color="interactive.action.subdued"
      css={{
        padding: '$half',
        textAlign: 'left',
        fontSize: '$bodyTwo',
        color: '$interactiveActionSubdued',
        fontWeight: 'normal',
        ...linkProps,
      }}
    >
      <Box as="span">
        {formatMessage({ id: text })}
        {icon && <Icon icon={icon} css={{ marginLeft: '$half' }} size="$one" />}
        {target === '_blank' && (
          <VisuallyHidden data-testid="new-tab-msg">
            {formatMessage({ id: 'STR_OPENS_IN_A_NEW_TAB' })}
          </VisuallyHidden>
        )}
      </Box>
    </TextAction>
  );
};
